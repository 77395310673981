// all the technologies ranked by how well i know them
export const technologies = [
    "Go", "Docker", "BASH/sh", "Python", "Git/GitHub",
    "Jenkins", "AWS", "PostgreSQL", "C/C++", "ReactJS", "Make",
    "Debian", "Kali", "Ubuntu", "Alpine", "Jupyter", "Flask",
    "JavaScript", "GraphQL", "MongoDB", "Redis", "Llama", "TailwindCSS",
    "Azure", "GoBUN", "SQlServer", "MySQL", "OpenCV", "Django", "ORM"
  ];
  

export function getRandomColor (){
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 10)];
  }
  return color;
};