import './App.css';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { NotFound } from './NotFound';
import { Home } from './Home';
import { Footer } from './Footer';

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="*" element={<NotFound/>} />
        <Route path="/" element={<Home/>} />
        <Route path="/index" element={<Home/>} />
        <Route path="/showcase" element={<Home/> } />
      </Routes>
      <Footer/>
    </HashRouter>
  );
}

export default App;
