import React from 'react';
import { Card, CardHeader, CardBody, CardFooter, Divider } from "@nextui-org/react";
import { Link } from "@nextui-org/react"; 
import { getRandomColor } from "./utils";
import { GrGithub } from "react-icons/gr";
import projectsData from "./projects.json";

// LEFT COLUMN - Projects and Intro
export function ProjectsDisplayer() {
    return (
        <div className="p-0">
            {/* PROJECTS - CardFolios */}
            <div className="flex flex-wrap mt-7 md:grid-cols-2">
            {projectsData.projects.map((project, index) => (
                <CardFolio
                key={index}
                name={project.name}
                link={project.link}
                bodyContent={project.description}
                stack={project.stack}
                />
            ))}
            </div>
       </div>
    );
}

function CardFolio({ name, link, bodyContent, stack }) {
    const linkColor = getRandomColor();
    return (
        <Card className="ml-2 lg:max-w-[210px] mt-1 mb-auto drop-shadow-xl border dark:border-zinc-800 transition-all duration-800 ease-in-out">
            <div className="flex flex-col h-full">
                <CardHeader className="flex-col">
                    <p className="text-md select-none text-center text-zinc-900 dark:text-neutral-300">{name}</p>
                    <p className="text-xs select-none text-neutral-600 dark:text-neutral-400 text-center">{stack}</p>
                </CardHeader>
                <Divider className="my-1 border-neutral-600" />
                <CardBody className="text-xs p-2 select-none mt-4 text-neutral-800 dark:text-neutral-400">
                    {bodyContent}
                </CardBody>
                <CardFooter className="flex justify-center items-center text-sm p-2">
                    <Link isExternal href={link} style={{ color: linkColor }}  // special case handling for 'This Showcase'
                    className={link === "#" ? "always-hovered hover:underline" : "hover:underline font-bold"}>
                        <GrGithub className="inline-block mr-1" /> {name.toLowerCase()}
                    </Link>
                </CardFooter>
            </div>
        </Card>
    );
}
