import React from 'react';
import { GrGithub, GrLinkedin } from "react-icons/gr";
import { Link } from "@nextui-org/react"; 

export function Footer() {
  return (
    <footer className="bg-neutral-900 text-white py-3 mt-auto">
      <div className="container mx-auto text-center">
        
        <p className="text-sm space-x-9">
          <Link href="https://github.com/guidoenr">
            <GrGithub className="inline-block mr-1" href="github.com/guidoenr"/> github.com/guidoenr 
          </Link>
          <Link href="https://linkedin.com/in/guidoenr">
            <GrLinkedin className="inline-block mr-1" href="linkedin.com/in/guidoenr"/> linkedin.com/in/guidoenr
          </Link>
        </p>
      </div>
    </footer>
  );
}
