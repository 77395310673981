export function NotFound() {
  const desc = "404 ".repeat(900);

  return (
    <div className=" text-red-900 bg-neutral-950">
        {desc}
          <b className="always-hovered" href="#">BE CAREFUL BRO</b>
        {desc}
    </div>
  );
}
