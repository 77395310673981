import { technologies } from "./utils";
import { useState } from "react";

{
  /* RIGHT Section - Search and Technologies */
}
export function TechsDisplayer() {
  const [search, setSearch] = useState("");
  const isHighlighted = (tech) => search && tech.toLowerCase().includes(search.toLowerCase());

  return (
    <div className="mt-10">
      <p className="text-sm text-neutral-900 dark:text-neutral-300">
        Below is the list of technologies I have worked with, ordered by experience/how well I know them.
      </p>

      <p className="text-xs mt-3 mb-10 text-neutral-500 dark:text-neutral-500">
        (yeah, I fully understand these words might be unfamiliar if you haven't worked as an engineer/developer, so I find this helpful for recruiters who prefer not to get stressed :) )
      </p>

      <SearchInput placeholder="Search ..." value={search} onChange={(e) => setSearch(e.target.value)} />
      
      <div className="grid grid-cols-3 gap-3 mt-5">
        {technologies.map((tech, index) => (
          <div
            key={index}
            className={`p-1 text-center transition-all duration-100 text-sm select-none transform ${
              isHighlighted(tech)
                ? "text-purple-900 bg-green-500 dark:bg-green-600"
                : "text-zinc-700 bg-zinc-100 dark:bg-zinc-950 dark:text-neutral-300 hover:font-extralight"
            }`}
          >
            {tech}
          </div>
        ))}
      </div>
    </div>
  );
}

function SearchInput({ value, onChange }) {
  return (
    <div className="relative">
      <input
        type="search"
        placeholder="Search ..."
        value={value}
        onChange={onChange}
        className="w-full py-2 px-3 pl-10 border dark:text-neutral-100 border-gray-300 rounded-md focus:outline-none bg-transparent"
      />
      <span className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
        <svg
          className="w-5 h-5 text-gray-500"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M11 4a7 7 0 00-7 7 7 7 0 007 7 7 7 0 007-7 7 7 0 00-7-7zM21 21l-4.35-4.35"
          />
        </svg>
      </span>
    </div>
  );
}
